import Util from "@/utils/utils";
import { getList, getExport } from "./api";

export default {
  name: "",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/threeQualityManagement/thirdQradeReport",
          name: "thirdQradeReport",
          title: "终包收货"
        }
      ],
      hideOrShowSize: true,
      columns: [
        {
          label: "发货单编码",
          prop: "code",
          width: 250,
        },
        {
          label: "订单编码",
          prop: "orderCode",
          width: 250,
        },
        {
          label: "所属分公司编码",
          prop: "purchaseOrgCisCode",
          width: 250,
        },
        {
          label: "所属分公司名称",
          prop: "purchaseOrgName",
          width: 250,
        },
        {
          label: "供应商名称",
          prop: "supplierName",
          width: 250,
        },
        {
          label: "门店/商家编码",
          prop: "shopShopCode",
          width: 250,
        },
        {
          label: "门店/商家名称",
          prop: "shopName",
          width: 250,
        },
        {
          label: '类型',
          prop: 'shopTypeName',
          width: 250,
        },
        {
          label: '发货时间',
          prop: 'deliveryDate',
          width: 250,
        },
        {
          label: '发货方式',
          prop: 'deliveryWayName',
          width: 250,
        },
        {
          label:'收货状态',
          prop:'statusName',
          width: 250,
        },
        {
          label: "快递公司",
          prop: 'expressCompany',
          width: 250,
        },
        {
          label: "快递单号",
          prop: 'expressNo',
          width: 250,
          
        },
        {
          label: "总金额",
          prop: 'totalAmount',
          width: 250,
          
        },
        {
          label: "操作",
          slot: "btnClick",
          width: 150,
          fixed: 'right',

        },
      ],
      tableData: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      ssearchList: {
      },
      searchNumber: 0,
      hideshow: false,
      isLoading: false,
      onluyOneMessage: false,

      tableLoading: false
    };
  },
  watch: {
    "ssearchList.consignee": function (val) {
      this.ssearchList.consignee = val.trim();
    },

  },
  mounted() {

    this.tablelist();
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {

    exportClick() {
      let data = {
        ...this.ssearchList
      }
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "终包收货提报.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },

    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.tablelist();
    },
    searchFun(arg) {
      this.pager.pageNo = 1
      this.ssearchList = {
        ...this.ssearchList,
        ...arg[0]
      };
      this.tablelist();
    },
    // 获取列表数据
    tablelist() {
      let data = {
        page: this.pager.pageNo,
        rows: this.pager.pageSize,
        ...this.ssearchList
      }
      this.tableLoading = true
      getList(data).then(res => {
        if (res.data.success) {
          if(  res.data.data) {
            this.tableData = res.data.data;
            if (res.data.data) {
              this.searchNumber = res.data.count;
              this.pager.count = res.data.count;
            }
            if(this.tableData && this.tableData.length > 0) {
                 // 表格数据重组
              this.tableData.forEach(tableItem => {
                tableItem.purchaseOrgCisCode = tableItem.purchaseOrg.cisCode
                tableItem.purchaseOrgName = tableItem.purchaseOrg.name
                tableItem.submitOrgCisCode = tableItem.supplier.mdmId
                tableItem.supplierName = tableItem.supplier.name
                tableItem.shopShopCode = tableItem.shop.shopCode
                tableItem.shopName = tableItem.shop.name
                tableItem.shopTypeName = tableItem.shop.type == '0'?'门店':'商家'
                tableItem.statusName = tableItem.status == '0'?'待收货':'已收货'
                // 0：快递，1：物流2：其他
                if(tableItem.deliveryWay == 0){
                  tableItem.deliveryWayName = '快递'
                }
                if(tableItem.deliveryWay == 1){
                  tableItem.deliveryWayName = '物流'
                }
                if(tableItem.deliveryWay == 2){
                  tableItem.deliveryWayName = '其他'
                }
              })
            }
          } else {
            this.tableData = []
          }
          this.tableLoading = false
        } else {
          this.$message.warning(res.data.msg)
          this.tableLoading = false
        }
      });
    },


    // 查看详情
    toDetail(row) {
      let routeUrl = this.$router.resolve({
        path: "/threeQualityManagement/thirdQradeReportDetail",
        query: { taskId: row.taskId, id:row.id },
      });
      window.open(routeUrl.href, "_blank");
    },

    hideOrShow(data) {
      this.hideOrShowSize = data;
    },
    handleChange(e) {},
    onChangeEndTime(e, time) {
      this.ssearchList.createTime = time[0] + '_' + time[1];
    },
    searchClick() {
      this.tablelist();
    }
  }
};
